// firebase.js
import firebase from "firebase/compat/app"
import 'firebase/compat/firestore';


// Firebase 설정
const firebaseConfig = {
  apiKey: "AIzaSyAVy_oHB7TG84TgNO1F105o8fWpzoV3Riw",
  authDomain: "hyperring-home-users.firebaseapp.com",
  projectId: "hyperring-home-users",
  storageBucket: "hyperring-home-users.appspot.com",
  messagingSenderId: "696366240989",
  appId: "1:696366240989:web:31d8af792a6100418e3734",
  measurementId: "G-GT75YWYW56"
};

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
export { firestore };


// Firebase 초기화
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const database = getDatabase(app);
// const db = getFirestore(app);

// export { app, analytics, database, db };
