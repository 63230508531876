import React from "react";

import { css } from "@emotion/css";
import Img from "../../../img/png/software-image.png";
import LogoImg from "../../../img/png/home-about-logo.png";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";

const Software = ({techText, techImage}) => (
  <div className={style.container}>
    <div className="wrapper">
      <img src={techImage[0] && techImage.filter(item => item.location == "software image")[0].image.asset.url} alt="hyper ring" className="ring" />
      <h2>{techText[0] && techText.filter(item => item.type == "software")[0].title}</h2>
      <img src={techImage[0] && techImage.filter(item => item.location == "software logo image")[0].image.asset.url} alt="logo" className="logo" />
      <p style={{ whiteSpace: 'pre-line'}}>
        {techText[0] && techText.filter(item => item.type == "software")[0].content}
      </p>
    </div>
  </div>
);

const style = {
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: 1830,
    padding: 40,
    paddingTop: 150,
    paddingBottom: 150,
    backgroundColor: "#f4f4f4",
    position: "relative",

    h2: {
      // color: "#1B243A",
      color: "#000",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      textAlign: "center",
      fontFamily: "Halvar Breitschrift",
      fontSize: 75,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-3px",
      // textTransform: "lowercase",
      marginBottom: 50,
    },

    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      textAlign: "center",
      // fontSize: 20,
      // lineHeight: "34px",
      letterSpacing: "-0.2px",
      // background: "linear-gradient(95deg, #1B243A 33.43%, #95A1BE 94.91%)",
      // backgroundClip: "text",
      // WebkitBackgroundClip: "text",
      // WebkitTextFillColor: "transparent",
    },

    ".wrapper": {
      borderRadius: 40,
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(103, 104, 132, 0.04) 81.99%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.50)",

      backdropFilter: "blur(10px)",
      maxWidth: 1240,
      height: "100%",
      margin: 40,
      padding: 95,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1,
    },
    ".ring": {
      maxWidth: 465.584,
      height: "100%",
      wight: "100%",
      flexShrink: 0,
    },

    ".logo": {
      marginBottom: 40,
      height: 24,
      wight: 24,
    },

    [desktop]: {
      h2: {
        fontSize: 68,
        letterSpacing: "-2.72px",
      },
    },
    [laptop]: {
      h2: {
        fontSize: 46,
        letterSpacing: "-1.84px",
      },
      p: {
        fontSize: 18,
        lineHeight: "30px",
      },
      ".ring": {
        maxWidth: 440,
      },
    },
    [tablet]: {
      padding: 20,

      ".wrapper": {
        margin: 0,
        padding: 60,
        paddingLeft: 40,
        paddingRight: 40,
        marginTop: 100,
        marginBottom: 20
      },
      p: {
        fontSize: 16,
        lineHeight: "28px",
      },
      ".ring": {
        maxWidth: 400,
      },
    },
    [mobile]: {
      padding: 20,

      ".wrapper": {
        paddingLeft: 20,
        paddingRight: 20,
      },
      h2: {
        fontSize: 36,
        letterSpacing: "-1.44px",
      },
      p: {
        fontSize: 14,
        lineHeight: "24px",
      },
      ".ring": {
        maxWidth: 400,
      },
    },
  }),
};

export default Software;
