import React from "react";

import { css } from "@emotion/css";
import BGImg from "../../../img/jpg/software-structure-bg.jpg";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";

const Structure = ({techText, techImage}) => (
  <div style={{ position: "relative" }}>
    <div
      className={style.bg}
      style={{ backgroundImage: `url(${techImage[0] && techImage.filter(item => item.location == "structure background image")[0].image.asset.url})` }}
    ></div>
    <div className={style.container}>
      <div className="wrapper">
        <h2>{techText[0] && techText.filter(item => item.type == "structure")[0].title}</h2>
        <p style={{ whiteSpace: 'pre-line'}}>
          {techText[0] && techText.filter(item => item.type == "structure")[0].content}
        </p>
      </div>
    </div>
  </div>
);

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 0%",
    backgroundSize: "cover",
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    opacity: 0.95,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    backgroundColor: "#f4f7f8",

    [laptop]: {
      backgroundPosition: '30% 0%'
    },
  }),
  container: css({
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    maxWidth: 1830,
    padding: 40,
    paddingTop: 300,
    paddingBottom: 300,
    zIndex: 1,
    position: 'relative',

    h2: {
      // color: '#1B243A',
      color: "#000",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontFamily: 'Suisse Intl',
      fontSize: 46,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      marginBottom: 20,
    },

    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl", 
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      // color: '#000',
      fontFamily: 'Suisse Intl',
      // fontSize: 20,
      // lineHeight: '34px',
      letterSpacing: '-0.2px',
    },

    ".wrapper": {
      maxWidth: 600,
      height: "100%",
      margin: 40,
      paddingBottom: 300,
      marginTop: '-300px',
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    [laptop]: {
      p: {
        fontSize: 18
      },
      ".wrapper": {
      }
    },
    [tablet]: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 100,

      ".wrapper": {
        margin: 0
      },
      h2: {
        fontSize: 40
      },
      p: {
        fontSize: 16
      },
    },
    [mobile]: {
      paddingTop: 50,
      paddingLeft: 20,
      paddingRight: 20,

      h2: {
        fontSize: 28
      },
      p: {
        fontSize: 14,
        lineHeight: '28px'
      },
    },
  }),
};

export default Structure;
