import React from "react";

import { css } from "@emotion/css";
import Img from "../../../img/png/team-misson.png";
import LogoImg from "../../../img/png/home-about-logo.png";
import { tablet } from "../../../styles/breakpoints";

const Mission = ({teamText, teamImage}) => (
  <div className={style.container}>
    <div className="wrapper">
      <div className="title">
        <img src={teamImage[0] && teamImage.filter(item => item.location == "mission image")[0].image.asset.url} alt="hyper ring" className="ring" />
        <h2>{teamText[0] && teamText.filter(item => item.type == "mission")[0].title}</h2>
      </div>
      <img src={teamImage[0] && teamImage.filter(item => item.location == "mission logo image")[0].image.asset.url} alt="logo" className="logo" />
      <p style={{ whiteSpace: 'pre-line'}}>
      {teamText[0] && teamText.filter(item => item.type == "mission")[0].content}
      </p>
    </div>
  </div>
);

const style = {
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: 1830,
    padding: 40,
    paddingBottom: 150,
    backgroundColor: "#f4f4f4",

    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      textAlign: "center",
      // fontSize: 20,
      lineHeight: "175%",
      letterSpacing: "-0.2px",
    },

    ".wrapper": {
      borderRadius: 40,
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(103, 104, 132, 0.04) 81.99%, rgba(255, 255, 255, 0.00) 100%), rgba(255, 255, 255, 0.50)",
      backdropFilter: "blur(10px)",
      maxWidth: 1240,
      height: "100%",
      margin: 40,
      padding: 80,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
    },

    ".logo": {
      marginBottom: 40,
      height: 24,
      wight: 24,
    },

    ".title": {
      position: "relative",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",

      h2: {
        color: "#A8B2C9",
        fontFamily: "Halvar Breitschrift",
        fontSize: 75,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "105%",
        letterSpacing: "-3px",
        // textTransform: "lowercase",
        marginBottom: 250,
        marginTop: 250,
        textAlign: "center",
      },
      ".ring": {
        height: "100%",
        flexShrink: 0,
        position: "absolute",
        top: 30,
        zIndex: -1,
      },
    },
    [tablet]: {
      padding: 30,

      ".wrapper": {
        margin: 0,
        padding: 30,
        marginBottom: 100,
      },
      ".title": {
        h2: {
          fontSize: 52,
          marginBottom: 200,
          marginTop: 200,
        },
      },
    },
    [tablet]: {
      padding: 20,

    p: {
      fontSize: 16,
    },

      ".wrapper": {
        margin: 0,
        padding: 20,
        marginBottom: 80,
      },
      ".title": {
        h2: {
          fontSize: 32,
          marginBottom: 150,
          marginTop: 150,
        },
      },
    },
  }),
};

export default Mission;
