import React, { useEffect, useRef, useState } from "react";
import { useInView, useMotionValueEvent, useScroll } from "framer-motion";
import { css } from "@emotion/css";
import SubmitButton from "../../../img/svg/submit-button.svg";
import Clickable from "../../../components/Clickable";
import { mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";
import { firestore } from "../../../firebase";

const Loop = () => {
  const [homeImage, setHomeImage] = useState([]);
  const [email, setEmail] = useState("");

  useEffect(() => {
    client
      .fetch(
        `*[_type == "homeImage"] {
          location,
          image {
            asset -> {
              _id,
              url
            }
          }
        }`
      )
      .then((data) => {
        setHomeImage(data);
      })
      .catch(console.error);
  }, []);

  const ref = useRef(null);
  const isInView = useInView(ref);
  const { scrollY } = useScroll();
  // const [slide, setSlide] = useState(1);
  const [autoScroll, setAutoScroll] = useState(true);
  // const [completed, setCompleted] = useState(false);

  const handleSubmit = async () => {

    // const test = firestore.collection("newsletter");
    // test.doc("test").get(test).then((doc) => {
    //   console.log(doc.data());
    //   console.log(doc.id);
    // })

    if (email) {
      const newsletter = firestore.collection("newsletter");
      newsletter.doc().set({email: email})
      .then(() =>{
        alert("Your email address has been saved. Thank you!");
          setEmail(""); // 이메일 필드를 비웁니다.
      })
      .catch((error) => {
        console.error("Error saving email: ", error);
        alert("There was an error saving your email. Please try again.");
      });
    } else {
      alert("Please enter a valid email address.");
    }
  };

  // function updateScrollPosition(e) {
  //   const value = e * 10;
  //   if (completed) {
  //     // setSlide(7);
  //     return;
  //   }

  //   if (value > 8.6) {
  //     // setSlide(7);
  //     setCompleted(true);
  //   } else if (value > 7.08) {
  //     // setSlide(6);
  //   } else if (value > 5.66) {
  //     // setSlide(5);
  //   } else if (value > 4.26) {
  //     // setSlide(4);
  //   } else if (value > 2.84) {
  //     // setSlide(3);
  //   } else if (value > 1.42) {
  //     // setSlide(2);
  //   } else {
  //     // setSlide(1);
  //   }
  // }

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (isInView && autoScroll) {
      const timeoutID = window.setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        setAutoScroll(false);
      }, 800);

      return () => window.clearTimeout(timeoutID);
    }
  });

  // useEffect(() => {
  //   const intervalId = window.setInterval(() => {
  //     scroll(updateScrollPosition, {
  //       source: document.getElementById("scroll_overlay_loop"),
  //     });
  //   }, 200);

  //   return () => window.clearInterval(intervalId);
  // }, [updateScrollPosition]);

  return (
    <div className={`${style.container(isInView, autoScroll)} slide${7}`}>
      <div className={"bg"}>
        <img
          src={
            homeImage != null &&
            homeImage[0] &&
            homeImage.filter((item) => item.location === "loop rings background image")[0].image.asset.url
          }
          alt=""
        />
      </div>

      <div className="heading">
        <h2>Stay in The Loop</h2>
      </div>

      <div className="body">
        <p>Enter your email below to receive our newsletter</p>
        <div className="wrapper">
          <div className="subscribe">
            <input
              name="email"
              id="email"
              type="email"
              placeholder="Email Address"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)} // 이메일 상태 업데이트
            />
            <Clickable className="submit" onClick={handleSubmit}> {/* 클릭 시 handleSubmit 실행 */}
              <img src={SubmitButton} alt="submit" width={34} height={34} />
            </Clickable>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  container: (isInView, autoScroll, completed) =>
    css({
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "stretch",
      maxWidth: 1830,
      margin: "auto",
      padding: 40,
      position: "relative",
      overflow: "hidden",
      zIndex: 1,

      "#scroll_overlay_loop": {
        width: "100%",
        height: "100%",
        position: isInView && autoScroll === false ? "fixed" : "absolute",
        left: 0,
        top: 0,
        overflow: "scroll",
        display: isInView && autoScroll === false ? "block" : "none",
        scrollbarWidth: "none",
      },
      "#scroll_anchor_loop": {
        position: "absolute",
        left: 0,
        bottom: "50%",
      },

      ".bg": {
        transition: "all 0.4s ease 0s",
        width: "100%",
        height: "100%",
        position: "absolute",
        backgroundSize: "cover",
        left: 0,
        top: 0,
        zIndex: "-1",
        maxWidth: 1920,
        margin: "auto",
        overflow: "hidden",

        img: {
          opacity: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transition: "all 1s",
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          overflow: "unset",
        },
      },
      ".heading": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        gap: 30,
        transition: "all 1s",
      },
      ".body": {
        opacity: 0,
        transition: "all 1s",
        transform: "scale(0.4)",
      },

      h2: {
        color: "#1B243A",
        fontFamily: "Halvar Breitschrift",
        fontSize: 64,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
      },

      p: {
        // color: "#1B243A",
        color: "rgba(27, 36, 58, 0.80)",
        textAlign: "center",
        fontFamily: "Suisse Intl",
        fontSize: 22.031,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "35.249px",
        marginBottom: 20,
      },
      ".wrapper": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",

        ".subscribe": {
          position: "relative",

          input: {
            borderRadius: 12,
            border: "1px solid rgba(109, 125, 161, 0.80)",
            background: "rgba(255, 255, 255, 0.15)",
            display: "flex",
            width: 390,
            height: 58,
            padding: "0px 12px 0px 24px",
            color: "#6D7DA1",
            fontFamily: "Suisse Intl",
            fontSize: 17.619,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "34.53px",
            gap: 30,
          },
          ".submit": {
            position: "absolute",
            right: 15,
            top: 15,
          },
        },
      },

      "&.slide1": {},
      "&.slide2": {
        ".heading": {
          gap: 160,
        },
      },
      "&.slide3": {
        ".heading": {
          opacity: 0,
        },
        ".bg": {
          img: {
            opacity: 1,
            transform: "rotate(15deg) scale(1.15)",
          },
        },
      },
      "&.slide4": {
        ".heading": {
          opacity: 0,
        },
        ".bg": {
          img: {
            opacity: 1,
            transform: "rotate(30deg)  scale(1.30)",
          },
        },
      },
      "&.slide5": {
        ".heading": {
          opacity: 0,
        },
        ".body": {
          opacity: 0.35,
          transform: "scale(0.6)",
        },
        ".bg": {
          img: {
            opacity: 1,
            transform: "rotate(45deg)  scale(1.45)",
          },
        },
      },
      "&.slide6": {
        ".heading": {
          opacity: 0,
        },
        ".body": {
          opacity: 0.7,
          transform: "scale(0.8)",
        },
        ".bg": {
          img: {
            opacity: 1,
            transform: "rotate(60deg)  scale(1.60)",
          },
        },
      },
      "&.slide7": {
        ".heading": {
          opacity: 0,
        },
        ".body": {
          opacity: 1,
          transform: "scale(1)",
          zIndex: 2,
        },
        ".bg": {
          img: {
            opacity: 1,
            transform: "rotate(75deg) scale(1.75)",
          },
        },
      },

      [tablet]: {
        padding: 30,

        ".heading": {
          opacity: 0,
        },
        ".body": {
          opacity: 1,
          transform: "scale(1)",
          zIndex: 2,
        },
        ".bg": {
          img: {
            opacity: 1,
            transform: "rotate(75deg) scale(1.75)",
          },
        },
        "#scroll_overlay_loop": {
          display: "none",
        },
        "#scroll_anchor_loop": {
          display: "none",
        },
        p: {
          fontSize: 18,
          lineHeight: "24px",
        },
        ".wrapper": {
          ".subscribe": {
            input: {
              width: 340,
            },
          },
        },
      },
      [mobile]: {
        padding: 20,
        p: {
          fontSize: 16,
          lineHeight: "22px",
        },
        ".wrapper": {
          alignItems: 'stretch',
          ".subscribe": {
            display: "flex",
            position: "relative",

            input: {
              width: '100%',
            },
          },
        },
      },
    }),
};

export default Loop;
