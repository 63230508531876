import React, { useEffect, useState } from "react";
import { css } from "@emotion/css";
import { mobile, tablet } from "../../styles/breakpoints";
import { client } from "../../lib/client";

const PrivacyPage = () => {
    const [privacy, setPrivacy] = useState([]);

    useEffect(() => {
        client.fetch(
        `*[_type == "privacy"] {
            privacy,
        }`
        ).then((data) => {
            setPrivacy(data);
            console.log('privacy',data);
        }).catch(console.error);
        window.scrollTo({top:0, behavior:'smooth'});
    },[]);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <p className={styles.content}>
          {privacy[0] && privacy[0].privacy}
        </p>
        {/* Add more content here */}
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: "flex",
    justifyContent: "center",
    padding: "100px 20px", // 상단 여백을 줍니다.
    minHeight: "100vh", // 페이지가 화면 전체를 차지하도록 설정합니다.
  }),
  contentWrapper: css({
    maxWidth: "1300px", // 콘텐츠의 최대 너비를 설정합니다.
    width: "100%",
    padding: "40px",
    borderRadius: "8px", // 모서리를 둥글게 만듭니다.
  }),
  title: css({
    // fontSize: "32px",
    // color: "#1B243A",
    // marginBottom: "40px",
    // textAlign: "center",
    // fontFamily:"Vollkorn,serif",
    // fontWeight: 600,
    // fontSize: 72,
    color: "#000",
    fontFamily: "Halvar Breitschrift",
    fontSize: 100,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "105%",
    letterSpacing: "-4px",
    //textTransform: "lowercase",
    background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    marginBottom: 45,

    [tablet]:{
        fontSize: "34px",
        h2: {
          fontSize: 36,
          letterSpacing: "-1.44px",
        },
    }
  }),
  content: css({
    whiteSpace: "pre-line",
    color: "rgba(27, 36, 58, 0.80)",
    fontFamily: "Suisse Intl",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    // maxWidth: 800,

    [tablet]:{
      fontSize: 16,
      lineHeight: "28px",
    }
  }),
};

export default PrivacyPage;
