import React from "react";

import { css } from "@emotion/css";
import SubLogo from "../../../img/svg/home/sub-logo.svg";
import BGImg from "../../../img/jpg/hardware-bg.jpg";
import HardwareImage from "../../../img/png/hardware-image.png";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";

const Hardware = ({techText, techImage}) => (
  <div style={{ position: "relative" }}>
    <div
      className={style.bg}
      style={{ backgroundImage: `url(${techImage[0] && techImage.filter(item => item.location == "hardware background image")[0].image.asset.url})` }}
    ></div>
    <div className={style.container}>
      <h2>{techText[0] && techText.filter(item => item.type == "hardware")[0].title}</h2>
      <div className="wrapper">
        <span className="img-wrapper">
          <img src={techImage[0] && techImage.filter(item => item.location == "hardware image")[0].image.asset.url} alt="" />
        </span>
        <p style={{ whiteSpace: 'pre-line'}}>
        {techText[0] && techText.filter(item => item.type == "hardware")[0].content}
        </p>
      </div>
    </div>
  </div>
);

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0% 0%",
    backgroundSize: "cover",
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    opacity: 0.95,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: "-1",
  }),
  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 1200,
    margin: "auto",
    padding: 40,
    paddingBottom: 200,
    paddingTop: 200,

    ".wrapper": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    h2: {
      fontFamily: "Halvar Breitschrift",
      fontSize: 100,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-4px",
      // textTransform: "lowercase",
      marginTop: 20,
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      marginBottom: 100
    },

    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      // color: "#000",
      // fontSize: 20,
      // lineHeight: "34px",
      letterSpacing: "-0.2px",
      textAlign: "left",
      maxWidth: '50%'
    },
    ".img-wrapper": {
      position: 'relative',
      width: '100%',
      maxWidth: 1300,
      height: '100%',
      marginLeft: '-30%',
      marginTop: '-5%',

      img: {
        marginLeft: "-0%",
        width: '100%',
        height: '100%'
      },
    },
    [desktop]: {
      h2: {
        fontSize: 120,
      },
    },
    [laptop]: {
      h2: {
        fontSize: 75,
      },
      p: {
        fontSize: 18
      }
    },
    [tablet]: {
      paddingBottom: 150,
      paddingTop: 150,
      paddingLeft: 30,
      paddingRight: 30,
      '.wrapper': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',

      },
      h2: {
        fontSize: 66,
        marginBottom: 50
      },
      p: {
        fontSize: 16,
        maxWidth: '100%'
      },
      ".img-wrapper": {
      margin: 'auto',

      img: {
        margin: 'auto',
        width: '100%',
        height: '100%'
      },
    },
    },
    [mobile]: {
      paddingBottom: 100,
      paddingTop: 100,
      paddingLeft: 20,
      paddingRight: 20,

      '.wrapper': {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',


      },
      h2: {
        fontSize: 40,
        marginBottom: 40
      },
      p: {
        fontSize: 14,
      },
    }
  }),
};

export default Hardware;
