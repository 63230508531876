import Loop from "../home/sections/Loop";
import styles from "./FAQ.styles";
import Hero from "./sections/Hero";
import Data from "./sections/Data";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function FAQ() {

  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={`${styles.container}`}>
      <Hero />
      <Data />
      <Loop />
    </div>
  );
}
