import { useLocation } from "react-router-dom";

import styles from "./Product.styles";
import Description from "./sections/Description";
import Listing from "./sections/Listing";
import More from "./sections/More";
import { useEffect, useState } from "react";
import { client } from "../../lib/client";
// import { products } from "../../helpers/products";

export default function Product() {
  const location = useLocation();

  const [products, setProducts] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "product"] | order(id asc) {
          id,
          image{
            asset ->{
              _id,
              url
            }
          },
          gallery[]{
            asset ->{
                _id,
                url
              }
          },
          slug,
          title,
          subtitle,
          price,
          description,
          payLink,
      }`
      ).then((data) => {
        setProducts(data);
      }).catch(console.error);
  },[]);

  const item =
    Object.values(products)
      .filter((v) => location.pathname.includes(v.slug))
      .shift() || null; 

  if(products.length==0){
    return null;
  }

  return (
    <div className={`${styles.container}`}>
      {item?.id && (
        <>
          <Listing item={item} />
          <Description description={item.description} />
          <More skip={item.id} />
        </>
      )}
    </div>
  );
}
