import { useEffect, useState } from "react";
import Loop from "../home/sections/Loop";
import styles from "./Team.styles";
import Hero from "./sections/Hero";
import Members from "./sections/Members";
import Mission from "./sections/Mission";
import { client } from "../../lib/client";

import { useLocation } from 'react-router-dom';

export default function Team() {
  const [teamText, setTeamText] = useState([]);
  const [teamImage, setTeamImage] = useState([]);

  useEffect(() => {
      client.fetch(
      `*[_type == "teamText"] {
          type,
          title,
          content,
      }`
      ).then((data) => {
        setTeamText(data);
      }).catch(console.error);
  },[]);

  useEffect(() => {
      client.fetch(
      `*[_type == "teamImage"] {
          location,
          image {
            asset -> {
                _id,
                url
            }
          }
      }`
      ).then((data) => {
        setTeamImage(data);

      }).catch(console.error);
  },[]);

  
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={`${styles.container}`}>
      <Hero teamText={teamText} teamImage={teamImage}/>
      <Mission teamText={teamText} teamImage={teamImage}/>
      <Members />
      <Loop />
    </div>
  );
}
