import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/home/Home";
import Technology from "./pages/technology/Technology";
import Team from "./pages/team/Team";
import FAQ from "./pages/faq/FAQ";
import Contact from "./pages/contact/Contact";
import Shop from "./pages/shop/Shop";
import Product from "./pages/product/Product";
import Privacy from "./pages/privacy/Privacy";

import "./firebase";


function App() {
  return (
    <React.StrictMode>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/technology" element={<Technology />} />
          <Route exact path="/shop" element={<Shop />} />
          <Route exact path="/product/:product" element={<Product />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
      </Router>
    </React.StrictMode>
  );
}

export default App;
