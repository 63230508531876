import PreOrder from "../home/sections/PreOrder";
import styles from "./Shop.styles";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Shop() {

  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className={`${styles.container}`}>
      <PreOrder />
    </div>
  );
}
