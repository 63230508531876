import React, { useEffect, useState } from 'react';

import styles from './Home.styles';
import Hero from './sections/Hero';
import About from './sections/About';
import PreOrder from './sections/PreOrder';
import Sectors from './sections/Sectors';
import OurPartners from './sections/OurPartners';
import Loop from './sections/Loop';
import Features from './sections/Features';
import { client } from '../../lib/client';

import { useLocation } from 'react-router-dom';

export default function Home() {
    const [homeText, setHomeText] = useState([]);
    const [homeImage, setHomeImage] = useState([]);

    useEffect(() => {
        client.fetch(
        `*[_type == "homeText"] {
            type,
            title,
            content,
        }`
        ).then((data) => {
        setHomeText(data);
        }).catch(console.error);
    },[]);

    useEffect(() => {
        client.fetch(
        `*[_type == "homeImage"] {
            location,
            image {
            asset -> {
                _id,
                url
            }
            }
        }`
        ).then((data) => {
        setHomeImage(data);
        }).catch(console.error);
    },[]);

    const location = useLocation();
    useEffect(() => {
      const hash = location.hash.replace('#', '');
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className={`${styles.container}`}>
            <Hero homeText={homeText} homeImage={homeImage}/>
            <About homeText={homeText} homeImage={homeImage}/>
            <Features homeText={homeText} homeImage={homeImage}/>
            <PreOrder />
            <Sectors homeText={homeText} homeImage={homeImage}/>
            <OurPartners homeText={homeText} homeImage={homeImage}/>
            <Loop />
        </div>
    );
}
