import React, { useEffect, useRef, useState } from "react";
import {
  useInView,
} from "framer-motion";

import { css } from "@emotion/css";
import ToggleImg from "../../../img/svg/sectors/toggle-button.svg";

import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const Sectors = ({homeText, homeImage}) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  // const { scrollY } = useScroll();
  const [slide, setSlide] = useState(1);
//  const [autoScroll, setAutoScroll] = useState(true);
  const [active, setActive] = useState(null);
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "sectors"] | order(number asc) {
        title,
        number,
        subtitle,
        description,
        services[] {
          icon{
            asset -> {
              _id,
              url
            }
          },
          title,
          description,
        }
      }`
    ).then((data) => {
      setSectors(data);
    }).catch(console.error);
  }, []);

  const items = [
    {
      title: sectors[0] && sectors.filter(item => item.number === 1)[0].title,
      number: sectors[0] && String(sectors.filter(item => item.number === 1)[0].number).padStart(2, '0'),
      subtitle: sectors[0] && sectors.filter(item => item.number === 1)[0].subtitle,
      description:
      sectors[0] && sectors.filter(item => item.number === 1)[0].description,

      services: [
        {
          icon: sectors[0] && sectors.filter(item => item.number === 1)[0].services[0].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 1)[0].services[0].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 1)[0].services[0].description,
        },
        {
          icon: sectors[0] && sectors.filter(item => item.number === 1)[0].services[1].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 1)[0].services[1].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 1)[0].services[1].description,
        },
        {
          icon: sectors[0] && sectors.filter(item => item.number === 1)[0].services[2].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 1)[0].services[2].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 1)[0].services[2].description,
        },
      ],
    },
    {
      title: sectors[0] && sectors.filter(item => item.number === 2)[0].title,
      number: sectors[0] && String(sectors.filter(item => item.number === 2)[0].number).padStart(2, '0'),
      subtitle: sectors[0] && sectors.filter(item => item.number === 2)[0].subtitle,
      description:
      sectors[0] && sectors.filter(item => item.number === 2)[0].description,

      services: [
        {
          icon: sectors[0] && sectors.filter(item => item.number === 2)[0].services[0].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 2)[0].services[0].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 2)[0].services[0].description,
        },
        {
          icon: sectors[0] && sectors.filter(item => item.number === 2)[0].services[1].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 2)[0].services[1].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 2)[0].services[1].description,
        },
        {
          icon: sectors[0] && sectors.filter(item => item.number === 2)[0].services[2].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 2)[0].services[2].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 2)[0].services[2].description,
        },
      ],
    },
    {
      title: sectors[0] && sectors.filter(item => item.number === 3)[0].title,
      number: sectors[0] && String(sectors.filter(item => item.number === 3)[0].number).padStart(2, '0'),
      subtitle: sectors[0] && sectors.filter(item => item.number === 3)[0].subtitle,
      description:
      sectors[0] && sectors.filter(item => item.number === 3)[0].description,

      services: [
        {
          icon: sectors[0] && sectors.filter(item => item.number === 3)[0].services[0].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 3)[0].services[0].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 3)[0].services[0].description,
        },
        {
          icon: sectors[0] && sectors.filter(item => item.number === 3)[0].services[1].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 3)[0].services[1].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 3)[0].services[1].description,
        },
        {
          icon: sectors[0] && sectors.filter(item => item.number === 3)[0].services[2].icon.asset.url,
          title: sectors[0] && sectors.filter(item => item.number === 3)[0].services[2].title,
          description:
          sectors[0] && sectors.filter(item => item.number === 3)[0].services[2].description,
        },
      ],
    },
  ];

  const [intervalTime, setIntervalTime] = useState(5000); // State to hold interval time

  const handleNext = () => {
    setSlide((prevSlide) => (prevSlide < items.length ? prevSlide + 1 : prevSlide));
    setNewInterval(); // Change interval time
  };

  const handlePrev = () => {
    setSlide((prevSlide) => (prevSlide > 1 ? prevSlide - 1 : prevSlide));
    setNewInterval(); // Change interval time
  };

  const setNewInterval = () => {
    // Generate a random interval time between 2000 and 20000 milliseconds
    //const randomInterval = Math.floor(Math.random() * (20000 - 2000 + 1)) + 2000;
    //setIntervalTime(randomInterval);
    setIntervalTime(10000);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => (prevSlide % items.length) + 1);
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [intervalTime, items.length]); 


  return (
    <div style={{ position: "relative" }}>
      <div
        className={style.bg}
        style={{ backgroundImage: `url(${homeImage[0] && homeImage.filter(item => item.location === "sectors background image")[0].image.asset.url})` }}
      ></div>
      <div className={style.container(isInView)}>
        <h2>Sectors</h2>
        <div className={style.slider}>
        <span style={{ position: "relative" }}>
          {Object.values(items).map((d, k) => (
            <div className={`items${k !== slide - 1 ? "" : " active"}`} key={k}>
              <div className="item">
                <span className="title-wrapper">
                  <h3>
                    {d.number} <b>{d.title}</b>
                  </h3>

                  <img
                    src={ToggleImg}
                    className="toggle-mobile"
                    alt=""
                    onClick={() => setActive(active === k ? null : k)}
                    style={{ transform: active === k ? "rotate(180deg)" : "" }}
                  />
                </span>
                <span>
                  <h5>{d.subtitle}</h5>
                  <p>{d.description}</p>
                </span>
              </div>
              <div className={`pros${active === k ? " active" : ""}`}>
                <span className="inner">
                  {Object.values(d.services).map((v, key) => (
                    <span key={key}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="2"
                        viewBox="0 0 1830 2"
                        fill="none"
                        className="stroke"
                        style={{ backgroundColor: "#E2E9FA" }}
                      >
                        <path d="M0 1H1830" stroke="#E2E9FA" />
                      </svg>
                      <div className="pro">
                        <span>
                          <img src={v.icon} alt="" />
                          <h5>{v.title}</h5>
                        </span>
                        <span>
                          <p>{v.description}</p>
                        </span>
                      </div>
                    </span>
                  ))}
                </span>
              </div>
            </div>
          ))}


          <span ref={ref} id="scroll_anchor"></span>

          <div tabIndex={1} id="scroll_overlay">
            <div style={{ width: "100%", height: 3000 }}></div>
          </div>
        </span>
        <div
          style={{
            marginTop: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
        <button
          onClick={handlePrev}
          disabled={slide === 1}
          className={style.btn}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={slide === items.length}
          className={style.btn}
        >
          Next
        </button>
        </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  btn:css({
    width: "127px",
    background: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: "auto 40px",
    padding: "18px 37.4px",
    transition: "all .8s",
    border: "none",

    "&:before": {
      background: "linear-gradient(to bottom, #6473941c, #1b243aab)",
      borderRadius: 19.683,
      content: '""',
      inset: 0,
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
      padding: ".125rem",
      position: "absolute",
      transition: "all .8s",
    },

    "&:hover": {
      color: "#79a6ff",
      "&:before": {
        background: "linear-gradient(to bottom, #6473941c, #79a6ffab)",
      },

      span: {
        color: "#79a6ff",
      },
    },
    span: {
      // color: "#1B243A",
      fontFamily: "Suisse Intl Bold",
      fontSize: 15.746,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: 3.149,
   //   textTransform: "uppercase",
      padding: "28px 0px",
      zIndex: 1,
    },
    [desktop]: {
      visibility: "visible",
    },
    [laptop]: {
      visibility: "collapse",
    },
    [tablet]: {
      visibility: "collapse",
    },
    [mobile]: {
      visibility: "collapse",
    },
  }),
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 80%",
    transition: "all .4s ease",
    width: "100%",
    height: 1555,
    position: "absolute",
    backgroundSize: "inherit",
    left: 0,
    top: 0,
    [desktop]: {
      height: 1250,
    },
    [tablet]: {
      backgroundSize: "cover",
      height: 1150,
    },
    [mobile]: {
      height: 850,
    },
  }),
  container: (isInView, autoScroll) =>
    css({
      display: "flex",
      position: "relative",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "stretch",
      height: "100%",
      maxWidth: 1830,
      margin: "auto",
      padding: 40,

      "#scroll_overlay": {
        width: "100%",
        height: "100%",
        position: isInView && autoScroll === false ? "fixed" : "absolute",
        left: 0,
        top: 0,
        overflow: "scroll",
        display: isInView && autoScroll === false ? "block" : "none",
        scrollbarWidth: "none",
      },
      "#scroll_anchor": {
        position: "absolute",
        left: 0,
        bottom: "50%",
      },

      h2: {
        fontFamily: "Halvar Breitschrift",
        fontSize: 140,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "105%",
        letterSpacing: "-5.6px",
        // textTransform: "lowercase",
        marginBottom: 570,
        height: 700,
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
      },

      ".items": {
        transition: "opacity 1s ease-out",
        opacity: 0,
        height: 0,
        overflow: "hidden",

        "&.active": {
          opacity: 1,
          height: "auto",
        },

        ".item": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          padding: "50px 50px",
          borderRadius: 20,
          background: "rgba(231, 235, 244, 0.50)",
          backdropFilter: "blur(17.700000762939453px)",
          gap: 10,
          marginBottom: 50,

          ".toggle-mobile": {
            display: "none",
          },
          span: {
            width: "100%",

            h5: {
              color: "#000",
              fontFamily: "Halvar Breitschrift",
              background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // color: "#1B243A",
              // fontFamily: "Suisse Intl",
              fontSize: 20,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              letterSpacing: "-0.2px",
              // textTransform: "uppercase",
              marginBottom: 13,
            },
            h3: {
              color: "#000",
              background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // color: "#1B243A",
              fontFamily: "Halvar Breitschrift",
              fontSize: 68,
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              // textTransform: "lowercase",
            },
            p: {
              color: "rgba(27, 36, 58, 0.80)",
              fontFamily: "Suisse Intl",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "28px",
              // color: "#1B243A",
              // fontFamily: "Suisse Intl",
              // fontSize: 14,
              // fontStyle: "normal",
              // fontWeight: 400,
              // lineHeight: "24px",
            },
          },
        },
      },

      ".stroke": {
        width: "100%",
        margin: 0,
        padding: 0,
        display: "block",
      },
      ".pros": {
        backgroundColor: "#f5f5f5",
        ".pro": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "40px 50px",
          gap: 10,

          span: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignContent: "center",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 16,

            h5: {
              fontFamily: "Suisse Intl",
              fontSize: 24,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "26px",
              textTransform: "capitalize",
              background:
                "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            },
            p: {
              color: "rgba(27, 36, 58, 0.80)",
              fontFamily: "Suisse Intl",
              fontSize: 16,
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "28px",
            },
          },
        },
      },

      [desktop]: {
        h2: {
          fontSize: 120,
          letterSpacing: "-4.8px",
          marginBottom: 500,
          height: 600,
        },
        ".items": {
          ".item": {
            flexDirection: "column",
            padding: "30px 50px",
            span: {
              h5: {
                marginBottom: 20,
              },
            },
            marginBottom: 0,
          },
        },
        ".pros": {
          ".pro": {
            padding: "40px 00px",
          },
        },
      },
      [laptop]: {
        h2: {
          fontSize: 90,
          letterSpacing: "-3.6px",
          marginBottom: 500,
          height: 600,
        },
        ".items": {
          opacity: 1,
          height: "100%",

          ".item": {
            flexDirection: "column",
            padding: "30px 30px",
            span: {
              h3: {
                fontSize: 46,
                marginBottom: 30,
              },
              p: {
                fontSize: 18,
                lineHeight: "30px",
              },
              h5: {
                fontSize: 20,
                letterSpacing: "-0.2px",
              },
            },
          },
        },
        ".pros": {
          ".pro": {
            flexDirection: "column",
            padding: "40px 30px",

            span: {
              h5: {
                fontSize: 20,
                lineHeight: "34px",
              },
              p: {
                fontSize: 18,
                lineHeight: "30px",
              },
            },
          },
        },
      },
      [tablet]: {
        padding: 30,

        "#scroll_overlay": {
          display: "none",
          scrollbarWidth: "none",
        },
        "#scroll_anchor": {
          display: "none",
        },

        h2: {
          fontSize: 75,
          letterSpacing: "-3px",
          marginBottom: 400,
          height: 550,
        },
        ".items": {
          marginBottom: 60,
          ".item": {
            padding: "30px 30px",

            ".toggle-mobile": {
              display: "block",
              width: 56,
              height: 37,
            },

            span: {
              "&.title-wrapper": {
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "space-between",
                alignItems: "flex-start",
              },
              h3: {
                fontSize: 40,
                marginBottom: 20,
              },
              p: {
                fontSize: 16,
                lineHeight: "30px",
              },
              h5: {
                fontSize: 18,
              },
            },
          },
        },
        ".pros": {
          display: "grid",
          gridTemplateRows: "0fr",
          transition: "grid-template-rows 500ms",

          ".inner": {
            overflow: "hidden",
          },

          "&.active": {
            gridTemplateRows: "1fr",
          },

          ".pro": {
            padding: "40px 30px",

            span: {
              h5: {
                fontSize: 18,
                lineHeight: "24px",
              },
              p: {
                fontSize: 16,
                lineHeight: "28px",
              },
            },
          },
        },
      },
      [mobile]: {
        padding: 20,
        h2: {
          fontSize: 46,
          letterSpacing: "-1.84px",
          marginBottom: 380,
          height: 350,
        },
        ".items": {
          ".item": {
            padding: "30px 20px",
            span: {
              h3: {
                fontSize: 32,
              },
              p: {
                fontSize: 14,
                lineHeight: "24px",
              },
              h5: {
                fontSize: 16,
                lineHeight: "24px",
              },
            },
          },
        },
        ".pros": {
          ".pro": {
            padding: "30px 20px",

            span: {
              h5: {
                fontSize: 16,
                lineHeight: "22px",
              },
              p: {
                fontSize: 14,
                lineHeight: "24px",
              },
            },
          },
        },
      },
    }),
};

export default Sectors;
