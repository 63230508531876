import { css } from '@emotion/css';

const style =  {
    container: css({
        '.gap':{
            marginBottom: 200
        }
    }),
};

export default style;