import React from "react";

import { css } from "@emotion/css";

import App1Img from "../../../img/png/app-1.png";
import App2Img from "../../../img/png/app-2.png";
import App3Img from "../../../img/png/app-3.png";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";

const App = ({techText, techImage}) => (
  <div className={style.container}>
    <div className="images">
      <img className="img-1" src={techImage[0] && techImage.filter(item => item.location == "app image 1")[0].image.asset.url} alt="" />
      <img className="img-2" src={techImage[0] && techImage.filter(item => item.location == "app image 2")[0].image.asset.url} alt="" />
      <img className="img-3" src={techImage[0] && techImage.filter(item => item.location == "app image 3")[0].image.asset.url} alt="" />
    </div>
    <div className="wrapper">
      <h2>{techText[0] && techText.filter(item => item.type == "app")[0].title}</h2>
      <p style={{ whiteSpace: 'pre-line'}}>
        {techText[0] && techText.filter(item => item.type == "app")[0].content}
      </p>
    </div>
  </div>
);

const style = {
  container: css({
    backgroundColor: "#000",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 40,
    paddingLeft: 63,
    paddingRight: 63,
    paddingBottom: 100,
    overflowY: 'hidden',
    margin: 'auto',

    h2: {
      color: '#FFF',
      fontFamily: 'Suisse Intl',
      fontSize: 75,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      marginBottom: 20
    },

    p: {
      color: '#FFF',
      fontFamily: 'Suisse Intl',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '34px',
      letterSpacing: '-0.2px',
    },

    ".images": {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: 80,
      marginRight: 80,
      marginBottom: 80,
      maxWidth: 1830,
      width: '30%',

      img: {
        maxHeight: 813,
        maxWidth: '100%',
        height: 'auto',
      },

      '.img-1, .img-3': {
        marginTop: "-200%"
      },
      '.img-2': {
        marginTop:'30%'
      }
    },

    ".wrapper": {
      height: "100%",
      margin: 40,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "flex-start",
      maxWidth: 1830,
    },
    [desktop]: {
      h2: {
        fontSize: 68,
      },
    },

    [laptop]: {
      paddingLeft: 52,
      paddingRight: 52,
      paddingBottom: 80,

      ".wrapper": {
        margin: 0,
      },

      h2: {
        fontSize: 46,
      },

      p: {
        fontSize: 18,
      },
    },

    [tablet]: {
      paddingLeft: 30,
      paddingRight: 30,
      paddingBottom: 45,

      h2: {
        fontSize: 40,
      },

      p: {
        fontSize: 16,
        lineHeight: '32px',
      },
    },

    [mobile]: {
      paddingLeft: 20,
      paddingRight: 20,

      h2: {
        fontSize: 28,
      },

      p: {
        fontSize: 14,
        lineHeight: '28px',
      },
    },
  }),
};

export default App;
