import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";

import BGImg from "../img/jpg/footer-bg.jpg";
import LIIcon from "../img/svg/LinkedIn_icon_white_In.svg"
import YTIcon from "../img/svg/Youtube_icon.svg"
import TWIcon from "../img/svg/tw-icon.svg";

import { laptop, mobile, tablet } from "../styles/breakpoints";
import { Link } from "react-router-dom";
import { client } from "../lib/client";

const Footer = () => {
  const [contacts, setContacts] = useState([]);
  const [whitePaper, setWhitePaper] = useState([]);

  useEffect(() => {
    client.fetch(
      `*[_type == "header"] {
        title,
        file{
          asset ->{
            _id,
            url
          }
        },
      }`
    ).then((data) => {
      setWhitePaper(data);
    }).catch(console.error);
  },[])


  useEffect(() => {
    client.fetch(
      `*[_type == "footer"] {
        contactNumber,
        email,
        x,
        instagram,
        telegram
      }`
    ).then((data) => {
      setContacts(data);
    }).catch(console.error);
  },[])

  if(whitePaper.length === 0){
    return null;
  }

  return (<footer style={{ position: "relative" }}>
    <div
      className={style.bg}
      style={{ backgroundImage: `url(${BGImg})` }}
    ></div>
    <div className={style.container}>
      <div className="menu">
        <div className="left">
          <h2>Contact</h2>
          <hr />
          {contacts[0] && <div className="content">
            <Link to={`mailto:${contacts[0].email}`}>E: {contacts[0].email}</Link>
          </div>}
        </div>

        <div className="right">
          <h2>More</h2>
          <hr />
          <div className="content">
            <Link to="/">HyperRing</Link>
            <Link to="/shop">Shop</Link>
            <Link to="/contact">Contact</Link>
            <Link to={whitePaper[0] && whitePaper.filter(item => item.title === "whitepaper")[0].file.asset.url} target="blank">Whitepaper</Link>
            <Link to="/technology">Technology</Link>
            <Link to="/team">Team</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/privacy">Privacy</Link>
            {/* <Link to="/shop">Shopping bag</Link> */}
          </div>
        </div>
      </div>
      <div className="social">
        <Link to={contacts[0] && contacts[0].x}>
          <img src={TWIcon} alt="twitter" />
        </Link>
        <Link to={contacts[0] && contacts[0].instagram}>
          <img src={LIIcon} alt="linkedin" />
        </Link>
        <Link to={contacts[0] && contacts[0].telegarm}>
          <img src={YTIcon} alt="youtbe" />
        </Link>
        <hr />
      </div>
      <div className="copy">
        <div className="left">
          <Link to="/">HyperRing</Link>
          <Link to="/shop">Shop</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div className="right">

          <Link>2024 © HyperRing</Link>
        </div>
      </div>
    </div>
  </footer>);
}

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    opacity: 0.95,
    position: "absolute",
    backgroundSize: "cover",
    left: 0,
    top: 0,
    zIndex: '-1',

    "&:before": {
      background:
        "linear-gradient(to top, rgb(247 247 247 / 0%) 0%, rgb(245 245 245) 50%, rgb(245 245 245) 100%)",
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 200,
    },
  }),

  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "stretch",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,
    paddingTop: 300,
    paddingBottom: 80,
    backgroundColor: '#f5f5f5',

    a: {
      color: "#1B243A",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "21px",
      letterSpacing: 0.64,
    },
    h2: {
      color: "#A5AEC3",
      fontFamily: "Halvar Breitschrift",
      fontSize: 52,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      marginBottom: 52,
    },
    ".menu": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "space-between",
      alignItems: "flex-start",
      gap: 16,
      position: "relative",

      hr: {
        height: 0,
        width: "100%",
        position: "absolute",
        top: 60,
        left: 0,
        borderTop: "1px solid #E2E9FA",
      },
      ".left": {
        textAlign: "left",

        ".content": {
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: 30,
        },
      },
      ".right": {
        position: "relative",
        textAlign: "right",

        ".content": {
          textAlign: "right",
          gap: 30,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          alignContent: "center",
          justifyContent: "flex-end",
          alignItems: "flex-start",

          a: {
            flex: "0 0 21%",
          },
        },
      },
    },

    ".social": {
      height: 465,
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      gap: 16,
      position: "relative",

      img: {
        width: 90,
        height: 90,
      },

      hr: {
        height: 0,
        width: "100%",
        position: "absolute",
        bottom: 30,
        left: 0,
        borderTop: "1px solid #F5E1E2",
      },
    },
    ".copy": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "space-between",
      alignContent: "flex-start",
      alignItems: "flex-start",
      gap: 16,

      ".left": {
        display: "flex",
        gap: 120,
      },
      ".right": {
        display: "flex",
        gap: 60,
      },
    },

    [laptop]: {
      padding: 30,
      paddingTop: 200,
      paddingBottom: 80,

      h2: {
        fontSize: 46,
      },
      ".menu": {
        hr: {
          height: 0,
          width: "100%",
          position: "absolute",
          top: 60,
          left: 0,
          borderTop: "1px solid #E2E9FA",
        },
        ".left": {
          textAlign: "left",

          ".content": {
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 30,
          },
        },
        ".right": {
          position: "relative",
          textAlign: "right",

          ".content": {
            textAlign: "right",
            gap: 30,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "flex-end",
            alignItems: "flex-start",

            a: {
              flex: "0 0 22%",
            },
          },
        },
      },

      ".social": {
        height: 350,

        hr: {
          height: 0,
          width: "100%",
          position: "absolute",
          bottom: 30,
        },
      },
      ".copy": {
        ".left": {
          display: "flex",
          gap: 30,
        },
        ".right": {
          display: "flex",
          gap: 30,
        },
      },
    },

    [tablet]: {
      h2: {
        fontSize: 40,
        marginBottom: 30,
      },
      a: {
        fontSize: 16,
      },
      ".social": {
        height: 150,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginTop: 40,

        img: {
          width: 56,
          height: 56,
        },
      },
      ".menu": {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignItems: "stretch",
        justifyContent: "space-around",
        gap: 30,

        ".left, .right": {
          textAlign: "left",

          hr: {
            top: "-20px",
          },

          ".content": {
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            flexDirection: "row",
            gap: 20,

            a: {
              textAlign: "left",
            },
          },
        },
      },
      ".copy": {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        alignContent: "stretch",
        justifyContent: "center",
        flexWrap: "nowrap",
        gap: 40,

        ".left, .right": {
          width: "100%",
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          justifyContent: "space-between",
        },
        a: {
          fontSize: 14,
        },
      },
    },
    [mobile]: {
      padding: 20,
      paddingTop: 140,
      paddingBottom: 60,

      [tablet]: {
        h2: {
          fontSize: 40,
          marginTop: 20,
          marginBottom: 30,
        },
        a: {
          fontSize: 16,
        },
        ".social": {
          height: 150,
          marginTop: 20,

          img: {
            width: 50,
            height: 50,
          },
        },
        ".menu": {
          gap: 20,

          ".right": {
            ".content": {
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "1fr 1fr",
            },
          },

          ".left, .right": {
            '.content': {
              flexDirection: 'column',
            },
            hr: {
              top: "65px",
            },
          }
        },
        ".copy": {
          gap: 30,
        },
      },
    },
  }),
};

export default Footer;
