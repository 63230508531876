import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import BGImg from "../../../img/jpg/home-sector-bg.jpg";
import BGVideo from "../../../video/mp4/layer-video.mp4";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const Layer = ({techText, techImage}) => {
  const [techVideo, setTechVideo] = useState([]);

    useEffect(() => {
        client.fetch(
        `*[_type == "techVideo"] {
            location,
            video{
              asset ->{
                _id,
                url
              }
            },
        }`
        ).then((data) => {
        setTechVideo(data);
        console.log('video',data);
        }).catch(console.error);
    },[]);

  return (
    <div className={style.container}>
      <div className="wrapper">
        <h2>{techText[0] && techText.filter(item => item.type == "layer")[0].title}</h2>
        <p style={{ whiteSpace: 'pre-line'}}>
          {techText[0] && techText.filter(item => item.type == "layer")[0].content}
        </p>
      </div>
      {techVideo[0] && (<video autoPlay loop muted playsInline webkit-playsinline="true" x5-playsinline="true">
        <source src={techVideo[0] && techVideo.filter(item => item.location == "layer background video")[0].video.asset.url} type="video/mp4" />
      </video>)}
    </div>
  );
}

const style = {
  container: css({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#f4f4f4",
    paddingTop: 210,
    overflow: 'hidden',

    ".wrapper": {
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    video: {
      width: "100%",
      height: "100%",
    },
    h2: {
      // color: "#1B243A",
      color: "#000",
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontFamily: "Suisse Intl",
      fontSize: 50,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      marginBottom: 42,
      maxWidth: 837,
      textAlign: 'center',
    },

    p: {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",

      // color: "#000",
      textAlign: "center",
      // fontSize: 20,
      lineHeight: "175%",
      letterSpacing: "-0.2px",
      maxWidth: 837,
    },

    [desktop]: {
      paddingTop: 180,

      h2: {
        fontSize: 46,
      },

    },
    [laptop]: {
      p: {
        fontSize: 18,
      },
    },
    [tablet]: {
      paddingTop: 120,

      h2: {
        fontSize: 40,
      },

      p: {
        fontSize: 16,
      },
      video: {
        width: 1600,
        height: '100%',
      },

      ".wrapper": {
        padding: 30,
      }
    },
    [mobile]: {
      paddingTop: 95,

      h2: {
        fontSize: 28,
        marginBottom: 30,
      },

      p: {
        fontSize: 14,
      },
      video: {
        width: 1200,
        height: '100%',
      },
      ".wrapper": {
        padding: 20,
      }
    },
  }),
};

export default Layer;
