import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import Clickable from "../../../components/Clickable";
import IconPlus from "../../../img/svg/plus-icon.svg";
import IconMinus from "../../../img/svg/minus-icon.svg";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from '../../../lib/client';

// const items = [
//   {
//     title: "Materials and Care",
//     desciption: `<ul>
//       <li>3 NFC chips embedded</li>
//       <li>7mm band</li>
//       <li>Made in Korea</li>
//       <li>HyperRing guarantees, internally and within its supply chain, respect for standards of Social & Environmental Responsibility in line with its sustainability strategy. HyperRing gold, platinum and diamonds are certified by the Responsible Jewellery Council whose mission is to promote responsible, ethical, social and environmental practices that guarantee human rights throughout the gold and diamond supply chain, from mines to retail.</li>
//       </ul>`,
//   },
//   {
//     title: "Shipping and Return Info",
//     desciption:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit Lorem ipsum dolor sit",
//   },
//   {
//     title: "Payment Options",
//     desciption:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit consectetur adipiscing elit Lorem ipsum dolor sit",
//   },
// ];

const Description = ({ description }) => {
  const [active, setActive] = useState(0);
  const [productText, setProductText] = useState([]);

  useEffect(() => {
    client.fetch(
    `*[_type == "productText"] | order(_createdAt asc){
        type,
        title,
        content,
    }`
    ).then((data) => {
      setProductText(data);
    }).catch(console.error);
},[]);

  return (
    <div className={style.container}>
      <div className="info">
        <h2>Pure Opulence</h2>
        <p>{description}</p>
      </div>
      <div className="items">
        {Object.values(productText.filter(item => item.type === "description")).map((v, k) => (
          <div className={`${k === active ? "active" : "inactive"} item`}>
            <Clickable
              className="toggle-button"
              onClick={() => setActive(k === active ? null : k)}
            >
              <img src={k === active ? IconMinus : IconPlus} alt="" />
            </Clickable>
            <h5>{v.title}</h5>
            <div>
              <span
                className="item-text"
                dangerouslySetInnerHTML={{ __html: v.content }}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={style.divider}></div>
    </div>
  );
};

const style = {
  divider: css({
    position: "absolute",
    textAlign: "center",
    height: 250,
    width: "100%",
    bottom: "-250px",
    background: "linear-gradient(0deg, hsl(0deg 0% 100% / 0%), #f4f4f4)",
    zIndex: "-1",
  }),
  container: css({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    margin: "auto",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 200,
    paddingtop: 50,
    backgroundColor: "#f4f4f4",
    zIndex: 1,

    ul: {
      marginLeft: "-15px",
    },
    ".info": {
      marginTop: 99,
      marginBottom: 80,
      display: "flex",
      justifyContent: "space-between",
      alignContent: "center",
      alignItems: "flex-start",
      flexDirection: "row",
      flexWrap: "nowrap",
      borderBottom: "solid 1px #E2E9FA",
      paddingLeft: 45,
      paddingRight: 45,
      gap: 200,
      paddingBottom: 20,

      h2: {
        color: "#000",
        background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
        backgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: "Halvar Breitschrift",
        fontSize: 100,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "105%",
        letterSpacing: "-4px",
        //textTransform: "lowercase",
        textAlign: "left",
        marginBottom: 25,
        maxWidth: 600,
      },
      p: {
        color: "rgba(27, 36, 58, 0.80)",
        fontFamily: "Suisse Intl",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        maxWidth: 800,
      },
    },

    ".items": {
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      maxWidth: 1225,
      gap: 0,

      ".item": {
        flex: "33.33%",
        display: "block",
        width: "fit-content",
        paddingTop: 50,
        paddingBottom: 50,
        paddingLeft: 80,
        borderBottom: "solid 1px #E2E9FA",
        position: "relative",
        backgroundColor: "#f4f4f4",

        ".item-text": {
          color: "rgba(27, 36, 58, 0.80)",
          fontFamily: "Suisse Intl",
          fontSize: 16,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "28px",
          paddingTop: 24,
        },

        h5: {
          color: "#1B243A",
          background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          fontFamily: "Halvar Breitschrift",
          fontSize: 60,
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "105%",
          letterSpacing: "-2.4px",
   //       textTransform: "uppercase",
        },

        ".toggle-button": {
          position: "absolute",
          top: 50,
          left: 30,
          width: "100%",
          height: "100%",
        },

        div: {
          display: "grid",
          gridTemplateRows: "0fr",
          transition: "grid-template-rows 500ms",

          span: {
            overflow: "hidden",
          },
        },

        "&.active": {
          div: {
            gridTemplateRows: "1fr",
          },
        },
      },
    },
    [desktop]: {
      ".info": {
        marginTop: 99,
        marginBottom: 40,
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItems: "flex-start",
        flexDirection: "row",
        flexWrap: "nowrap",
        borderBottom: "solid 1px #E2E9FA",
        paddingLeft: 45,
        paddingRight: 45,
        gap: 70,
        paddingBottom: 20,
        width: "90%",

        h2: {
          fontSize: 68,
          letterSpacing: "-2.72px",
          textAlign: "center",
        },
        p: {
          fontSize: 14,
          lineHeight: "24px",
          textAlign: "center",
        },
      },

      ".items": {
        ".item": {
          h5: {
            fontSize: 46,
            letterSpacing: "-1.84px",
          },
        },
      },
    },
    [laptop]: {
      ".info": {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,

        h2: {
          fontSize: 68,
          letterSpacing: "-2.72px",
        },
        p: {
          fontSize: 14,
          lineHeight: "24px",
        },
      },

      ".items": {
        ".item": {
          ".item-text": {},

          h5: {
            fontSize: 46,
            letterSpacing: "-1.84px",
          },
        },
      },
    },
    [tablet]: {
      ".info": {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        paddingLeft: 0,
        paddingRight: 0,

        h2: {
          fontSize: 36,
          letterSpacing: "-1.44px",
        },
        p: {
          fontSize: 16,
          lineHeight: "28px",
        },
      },

      ".items": {
        ".item": {
          paddingLeft: 40,
          ".item-text": {},

          h5: {
            fontSize: 32,
            letterSpacing: "-1.28px",
          },

          ".toggle-button": {
            left: 0 ,
          },
        },
      },
    },
    [mobile]: {
      paddingLeft: 20,
      paddingRight: 20,
      ".info": {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        gap: 20,
        marginBottom: 0,

        h2: {
          fontSize: 36,
          letterSpacing: "-1.44px",
        },
      },

      ".items": {
        ".item": {
          paddingLeft: 40,
          ".item-text": {
            marginLeft: '-30px',
          },

          h5: {
            fontSize: 24,
          },

          ".toggle-button": {
            left: 0 ,
          },
        },
      },
    },
  }),
};

export default Description;
