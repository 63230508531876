import React, { useEffect, useState } from "react";

import { css } from "@emotion/css";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { client } from "../../../lib/client";

const Hero = () => {
  const [faqImage, setFaqImage] = useState([]);

    useEffect(() => {
      client.fetch(
      `*[_type == "faqImage"] {
              location,
              image {
                asset -> {
                  _id,
                  url
                }
              }
          }`
      ).then((data) => {
        setFaqImage(data);
      }).catch(console.error);
  },[]);

  if(faqImage.length === 0){
    return null;
  }

  const styles = style(faqImage);

  return (
    <div style={{ position: "relative" }}>
      <div className={styles.bg}></div>
      <div className={styles.container}>
        <div className="wrapper">
          <h1>
            FAQ
            <img src={faqImage[0] && faqImage.filter(item => item.location === "hero sub logo")[0].image.asset.url} alt="Logo" className="logo" />
          </h1>
        </div>
      </div>
      <div className={styles.divider}></div>
    </div>
  );
}

const style = (faqImage) => ({
  bg: css({
    backgroundImage: `url(${faqImage[0] && faqImage.filter(item => item.location === "hero background image")[0].image.asset.url})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    transition: "all .4s ease",
    width: "100%",
    height: 1242,
    opacity: 0.95,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: "-1",
    [laptop]: {
      height: 1042,
    },
    [tablet]: {
      backgroundImage: `url(${faqImage[0] && faqImage.filter(item => item.location === "hero background mobile image")[0].image.asset.url})`,
      height: 842,
    },
    [mobile]: {
      height: 600,
    }
  }),
  divider: css({
    position: "absolute",
    textAlign: "center",
    height: 150,
    width: "100%",
    bottom: 0,
    background: "linear-gradient(0deg, #f4f4f4, hsl(0deg 0% 100% / 0%))",
  }),
  container: css({
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,
    paddingBottom: 300,
    height: "100vh",

    h1: {
      color: "#fff",
      fontFamily: "Halvar Breitschrift",
      fontSize: 215,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-5.6px",
      // textTransform: "lowercase",
      marginTop: 60,
    },
    ".logo": {
      marginTop: "auto",
      marginBottom: -20,
      marginLeft: 20,
      width: 60,
      height: 56,
    },
    [laptop]: {
      padding: 30,

      h1: {
        fontSize: 140,
      },
      ".logo": {
        marginTop: "auto",
        marginBottom: -20,
        marginLeft: 20,
        width: 39 ,
        height: 42,
      },
    },
    [tablet]: {
      height: "800px",
      paddingBottom: 200,
      padding: 20,
      ".logo": {
        marginTop: "auto",
        marginBottom: -20,
        marginLeft: 10,
        width: 26 ,
        height: 28,
      },
      h1: {
        fontSize: 100,
      },
    },
    [mobile]: {
      height: "600px",
      paddingBottom: 100,
      padding: 10,
      ".logo": {
        marginTop: "auto",
        marginBottom: -20,
        marginLeft: 0,
        width: 18 ,
        height: 19,
      },
      h1: {
        fontSize: 68,
        marginTop: 80
      },
    },
  }),
});

export default Hero;
