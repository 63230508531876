import { useEffect, useState } from "react";
import Loop from "../home/sections/Loop";
import PreOrder from "../home/sections/PreOrder";
import styles from "./Technology.styles";
import App from "./sections/App";
import Hardware from "./sections/Hardware";
import Hero from "./sections/Hero";
import Layer from "./sections/Layer";
import Software from "./sections/Software";
import Structure from "./sections/Structure";
import { client } from "../../lib/client";

import { useLocation } from 'react-router-dom';

export default function Technology() {
  const [techText, setTechText] = useState([]);
  const [techImage, setTechImage] = useState([]);

  useEffect(() => {
      client.fetch(
      `*[_type == "techText"] {
          type,
          title,
          content,
      }`
      ).then((data) => {
      setTechText(data);
      console.log('tech text',data);
      }).catch(console.error);
  },[]);

  useEffect(() => {
      client.fetch(
      `*[_type == "techImage"] {
          location,
          image {
          asset -> {
              _id,
              url
          }
          }
      }`
      ).then((data) => {
      setTechImage(data);
      }).catch(console.error);
  },[]);

  const location = useLocation();
  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  
  return (
    <div className={`${styles.container}`}>
      <Hero techText={techText} techImage={techImage}/>
      <Software techText={techText} techImage={techImage}/>
      <Structure techText={techText} techImage={techImage}/>
      <App techText={techText} techImage={techImage}/>
      <Layer techText={techText} techImage={techImage}/>
      <Hardware techText={techText} techImage={techImage}/>
      <PreOrder />
      <div class="gap"></div>
      <Loop />
    </div>
  );
}
