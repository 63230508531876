import React from "react";

const SizeGuide = () => {
  return (
    <div style={styles.sizeGuideContainer}>
      <h3 style={styles.subtitle}>Size Guide</h3>
      <h4 style={styles.findSizeText}>Find the Right Size</h4>

      <div style={styles.radioButtons}>
        <label style={styles.label}>
          <input type="radio" name="unit" defaultChecked /> cm
        </label>
        <label style={styles.label}>
          <input type="radio" name="unit" /> inches
        </label>
      </div>

      <table style={styles.sizeTable}>
        <thead>
          <tr>
            <th style={styles.tableHeader}>HyperRing size</th>
            <th style={styles.tableHeader}>EU</th>
            <th style={styles.tableHeader}>UK</th>
            <th style={styles.tableHeader}>US</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index} style={index % 2 === 0 ? styles.evenRow : styles.oddRow}>
              <td style={styles.tableData}>{row.hyperRing}</td>
              <td style={styles.tableData}>{row.eu}</td>
              <td style={styles.tableData}>{row.uk}</td>
              <td style={styles.tableData}>{row.us}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

// 스타일 정의
const styles = {
  sizeGuideContainer: {
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "400px",
    margin: "0 auto",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  subtitle: {
    fontSize: "20px",
    textAlign: "left", // 중앙 정렬에서 좌측 정렬로 변경
    marginBottom: "10px",
  },
  findSizeText: {
    fontSize: "18px",
    textAlign: "left", // 중앙 정렬에서 좌측 정렬로 변경
    marginBottom: "20px",
  },
  radioButtons: {
    display: "flex",
    justifyContent: "flex-start", // 중앙 정렬에서 좌측 정렬로 변경
    marginBottom: "20px",
  },
  label: {
    marginRight: "20px",
    fontSize: "16px",
  },
  sizeTable: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tableHeader: {
    backgroundColor: "#f5f5f5",
    padding: "10px",
    border: "1px solid #ddd",
    fontWeight: "bold",
    textAlign: "center",
  },
  tableData: {
    padding: "10px",
    border: "1px solid #ddd",
    textAlign: "center",
  },
  evenRow: {
    backgroundColor: "#f9f9f9",
  },
  oddRow: {
    backgroundColor: "white",
  },
};

// 테이블 데이터
const tableData = [
  { hyperRing: 46, eu: 46, uk: "H", us: "3 3/4" },
  { hyperRing: 47, eu: 47, uk: "H½", us: "4" },
  { hyperRing: 48, eu: 48, uk: "I", us: "4 1/2" },
  { hyperRing: 49, eu: 49, uk: "J", us: "4 3/4" },
  { hyperRing: 50, eu: 50, uk: "K", us: "5 1/4" },
  { hyperRing: 51, eu: 51, uk: "L", us: "5 3/4" },
  { hyperRing: 52, eu: 52, uk: "L½", us: "6" },
  { hyperRing: 53, eu: 53, uk: "M", us: "6 1/4" },
  { hyperRing: 54, eu: 54, uk: "N", us: "6 3/4" },
  { hyperRing: 55, eu: 55, uk: "O", us: "7 1/4" },
  { hyperRing: 56, eu: 56, uk: "O½", us: "7 1/2" },
  { hyperRing: 57, eu: 57, uk: "P", us: "8" },
];

export default SizeGuide;
