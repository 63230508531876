import React, { useRef, useState } from "react";
import { useInView, useMotionValueEvent, useScroll } from "framer-motion";

import { css } from "@emotion/css";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";

const Features = ({homeText, homeImage}) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const { scrollY } = useScroll();
  const [slide, setSlide] = useState(1);

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (isInView) {
      const timeoutID = window.setTimeout(() => {
        const percentY = (100 * (latest - ref.current.offsetParent.offsetHeight)) / (ref.current.offsetParent.offsetHeight);

        if (percentY > 75) {
          setSlide(4);
        } else if (percentY > 50) {
          setSlide(3);
        } else if (percentY > 25) {
          setSlide(2);
        }else {
          setSlide(1);
        }
      }, 200);

      return () => window.clearTimeout(timeoutID);
    }
  });

  return (
    <div style={{ position: "relative", background: "#f5f5f5" }}>
      <div className={style.bg}>
        <img className={`ring-animate a${slide}`} src={homeImage[0] && homeImage.filter(item => item.location === "feature ring image")[0].image.asset.url} alt="" />
        <span></span>
      </div>

      <div className={style.container}>
        <h2>
          Key
          <br />
          Features
        </h2>
        <div className="wrapper" ref={ref}>
          <div className={`item i1${slide >= 2 ? " active" : ""}`}>
            <img src={homeImage[0] && homeImage.filter(item => item.location === "about logo image")[0].image.asset.url} alt="hyper ring logo" width={24} height={24} />
            <h5>{homeText[0] && homeText.filter(item => item.type === "key feature 1")[0].title}</h5>
            <p>
              {homeText[0] && homeText.filter(item => item.type === "key feature 1")[0].content}
            </p>
          </div>
          <div className={`item i2${slide >= 2 ? " active" : ""}`}>
            <h5>{homeText[0] && homeText.filter(item => item.type === "key feature 2")[0].title}</h5>
            <p>
            {homeText[0] && homeText.filter(item => item.type === "key feature 2")[0].content}
            </p>
          </div>
          <div className={`item i3${slide >= 3 ? " active" : ""}`}>
            <h5>{homeText[0] && homeText.filter(item => item.type === "key feature 3")[0].title}</h5>
            <p>
            {homeText[0] && homeText.filter(item => item.type === "key feature 3")[0].content}
            </p>
          </div>
          <div className={`item i4${slide >= 4 ? " active" : ""}`}>
            <h5>{homeText[0] && homeText.filter(item => item.type === "key feature 4")[0].title}</h5>
            <p>
            {homeText[0] && homeText.filter(item => item.type === "key feature 4")[0].content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = {
  bg: css({
    transition: "all .4s ease",
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    overflow: "hidden",
    background: "#f5f5f5",

    img: {
      position: "absolute",
      transitionDuration: "0.8s",
      transitionProperty: "transform",
      top: 0,
      left: "20%",
      zIndex: 0,
      right: 0,
      margin: "auto",

      "&.a1": {
        transform: "rotate(30deg)",
      },
      "&.a2": {
        transform: "rotate(60deg)",
      },
      "&.a3": {
        transform: "rotate(90deg)",
      },
      "&.a4": {
        transform: "rotate(120deg)",
      },
    },
    span: {
      width: "100%",
      height: "50%",
      bottom: 0,
      left: 0,
      background: "#f5f5f5",
      zIndex: 1,
      display: "block",
      position: "absolute",

      "&:before": {
        background:
          "linear-gradient(to top, rgb(247 247 247) 0%, rgb(245 245 245) 50%, rgba(0, 0, 0, 0) 100%)",
        content: "''",
        position: "absolute",
        top: -200,
        left: 0,
        width: "100%",
        height: 200,
      },
    },
  }),

  container: css({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 1830,
    margin: "auto",
    padding: 40,
    overflow: "hidden",
    backgroundColor: "#f5f5f5",
    paddingTop: 80,
    zIndex: 1,

    "&:before": {
      background:
        "linear-gradient(to top, #f5f5f5 0%, #f7f7f780 50%, #ffffffe8 100%)",
      content: '""',
      position: "absolute",
      top: "-200px",
      left: 0,
      width: "100%",
      height: 200,

      [tablet]: {
        background:
          "linear-gradient(to top, #f5f5f5 0%, #f7f7f700 50%, #ffffff00 100%)",
      },
    },

    h2: {
      color: "#000",
      fontFamily: "Halvar Breitschrift",
      fontSize: 140,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "105%",
      letterSpacing: "-5.6px",
      marginBottom: 190,
      background: "linear-gradient(108deg, #1B243A 17.98%, #95A1BE 105.95%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      zIndex: 1,
    },

    ".wrapper": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridTemplateRows: "1fr 1fr 1fr",
      gap: 20,
      gridTemplateAreas: `
                        'i1 i2 i2'
                        'i1 i3 i3'
                        'i4 i4 i4'`,

      img: {
        marginBottom: 26,
      },
      h5: {
        color: "#000",
        fontFamily: "Halvar Breitschrift",
        background: "-webkit-linear-gradient(#1B243A, #95A1BE)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        textAlign: "center",
        // fontFamily: "Suisse Intl",
        fontSize: 28,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "39px",
        // textTransform: "uppercase",
        marginBottom: 30,
      },
      p: {
        color: "rgba(27, 36, 58, 0.80)",
        fontFamily: "Suisse Intl",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        // color: "#000",
        textAlign: "center",
        // fontFamily: "Suisse Intl",
        // fontSize: 20,
        // fontStyle: "normal",
        // fontWeight: 400,
        // lineHeight: "34px",
        letterSpacing: "-0.2px",
        maxWidth: 1000,
      },

      ".i1": {
        gridArea: "i1",
        left: "-100%",
        position: "relative",
        transitionProperty: "left",
        transitionDuration: "1.8s",

        "&.active": {
          left: 0,
        },
      },
      ".i2": {
        gridArea: "i2",
        right: "-100%",
        position: "relative",
        transitionProperty: "right",
        transitionDuration: "1.8s",

        "&.active": {
          right: 0,
        },
      },
      ".i3": {
        gridArea: "i3",
        right: "-100%",
        position: "relative",
        transitionProperty: "right",
        transitionDuration: "1.8s",

        "&.active": {
          right: 0,
        },
      },
      ".i4": {
        gridArea: "i4",
        left: "-100%",
        position: "relative",
        transitionProperty: "left",
        transitionDuration: "1.8s",

        "&.active": {
          left: 0,
        },
      },

      ".item": {
        flex: 1,
        borderRadius: 63,
        background: '#ababab33',
        boxShadow: '0px 0px 63.621px 31.015px #FFF inset, 0px 0px 63.621px 31.015px #ffffff inset',
        padding: "70px 150px",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        alignContent: "center",
      },
    },
    [desktop]: {
      h2: {
        fontSize: 120,
        fontWeight: 400,
        letterSpacing: "-4.8px",
        marginBottom: 130,
      },
      ".wrapper": {
        h5: {
          fontSize: 24,
          lineHeight: "39px",
        },
        ".item": {
          flex: 1,
          padding: "70px 50px",
          gap: 10,
        },
      },
    },
    [laptop]: {
      paddingTop: 60,
      h2: {
        fontSize: 90,
        letterSpacing: "-3.6px",
        marginBottom: 110,
      },
      ".wrapper": {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr 1fr",
        gap: 20,
        gridTemplateAreas: `
            'i1 i2'
            'i3 i4'`,

        img: {
          marginBottom: 26,
        },
        h5: {
          fontSize: 20,
          marginBottom: 24,
          letterSpacing: "-0.2px",
        },
        p: {
          fontSize: 18,
          lineHeight: "30px",
        },

        ".item": {
          padding: 40,
          left: 0,
          right: 0,
        },
      },
    },
    [tablet]: {
      paddingTop: 60,
      h2: {
        fontSize: 75,
        letterSpacing: "-3px",
        marginBottom: 80,
      },
      ".wrapper": {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        gap: 20,
        gridTemplateAreas: `
          'i1'
          'i2'
          'i3'
          'i4'`,


        img: {
          marginBottom: 26,
        },
        h5: {
          fontSize: 20,
          marginBottom: 24,
        },
        p: {
          fontSize: 16,
          lineHeight: "28px",
        },

        ".item": {
          padding: "50px 30px",
        },
      },
    },
    [mobile]: {
      padding: 20,
      h2: {
        fontSize: 46,
        letterSpacing: "-1.84px",
        marginBottom: 60,
      },
      ".wrapper": {
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr",
        gap: 20,
        gridTemplateAreas: `
          'i1'
          'i2'
          'i3'
          'i4'`,

        img: {
          marginBottom: 10,
        },
        h5: {
          fontSize: 18,
          marginBottom: 16,
        },
        p: {
          fontSize: 14,
          lineHeight: "24px",
        },

        ".item": {
          padding: "40px 25px",
        },
      },
    },
  }),
};

export default Features;
