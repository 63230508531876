import { css } from "@emotion/css";
import { tablet } from "./breakpoints";

const buttons = {
  primary: css({
    display: "flex",
    minHeight: 40,
    justifyContent: "center",
    alignItems: "center",
    gap: 16.447,
    position: "relative",
    width: "auto",
    minWidth: 200,
    transition: "all .8s",
    color: "#1B243A",

    "&:before": {
      background: "linear-gradient(to bottom, #6473941c, #1b243aab)",
      borderRadius: 12,
      content: '""',
      inset: 0,
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
      padding: ".125rem",
      position: "absolute",
      transition: "all .8s",
    },

    span: {
      fontFamily: "Suisse Intl",
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: "0.12em",
      padding: "12px 24px",
      color: "#1B243A",
      zIndex: 1,
      display: "block",
      textAlign: "center",
      transition: "all .8s",
    },

    "&:hover": {
      color: "#79a6ff",
      "&:before": {
        background: "linear-gradient(to bottom, #6473941c, #79a6ffab)",
      },

      span: {
        color: "#79a6ff",
      },
    },

    [tablet]: {
      span: {
        fontSize: 14,
        letterSpacing: "1.4px",
        padding: "8px 20px",
      },
    },
  }),

  partner: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: "auto",
    padding: "18px 37.4px",
    transition: "all .8s",

    "&:before": {
      background: "linear-gradient(to bottom, #6473941c, #1b243aab)",
      borderRadius: 19.683,
      content: '""',
      inset: 0,
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
      padding: ".125rem",
      position: "absolute",
      transition: "all .8s",
    },

    "&:hover": {
      color: "#79a6ff",
      "&:before": {
        background: "linear-gradient(to bottom, #6473941c, #79a6ffab)",
      },

      span: {
        color: "#79a6ff",
      },
    },
    span: {
      color: "#1B243A",
      fontFamily: "Suisse Intl Bold",
      fontSize: 15.746,
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
      letterSpacing: 3.149,
   //   textTransform: "uppercase",
      padding: "28px 0px",
      zIndex: 1,
    },
  }),
};

export default buttons;
