const mobile = `@media (max-width: 481px)`;
const tablet = `@media (max-width: 767px)`;
const laptop = `@media (max-width: 1023px)`;
const desktop = `@media (max-width: 1399px)`;

export {
  mobile,
  tablet,
  laptop,
  desktop,
}