import React from "react";

import { css } from "@emotion/css";
import { desktop, laptop, mobile, tablet } from "../../../styles/breakpoints";


const About = ({homeText, homeImage}) => {
  return (
    <div className={style.container}>
      <div className="wrapper">
        <div className="header">
          <h2 className="left">About</h2>
          <img src={homeImage[0] && homeImage.filter(item => item.location === "about image")[0].image.asset.url} alt="about" />
          <h2 className="right">
            Hyper
            <br />
            Ring
          </h2>
        </div>
        <div className="text">
          <img src={homeImage[0] && homeImage.filter(item => item.location === "about logo image")[0].image.asset.url} alt="hyper ring logo" width={24} height={24} />
          <p style={{ whiteSpace: 'pre-line'}}>
            {homeText[0] && homeText.filter(item => item.type === "about description")[0].title}
          </p>
        </div>
        <span className="overlay"></span>
      </div>
    </div>
  );
}

const style = {
  container: css({
    width: "100%",
    display: "flex",
    marginBottom: 160,
    marginTop: 400,
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",

    ".wrapper": {
      borderRadius: 40,
      background:
        "linear-gradient(180deg, rgba(236, 242, 250, 0.50) 0%, rgba(242, 244, 247, 0.50) 100%)",
      backdropFilter: "blur(10px)",
      maxWidth: 1240,
      height: "100%",
      margin: 40,
      paddingTop: 100,
      paddingLeft: 70,
      paddingRight: 70,
      paddingBottom: 60,

      ".overlay": {
        borderRadius: 40,
        // background:
          // "linear-gradient(95deg, #1b243a00 33.43%, #f8f9fb7a 94.91%)",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 2,
      },
    },
    ".header": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",

      img: {
        maxWidth: 620,
      },
      h2: {
        color: "#A8B2C9",
        fontFamily: "Halvar Breitschrift",
        fontSize: 74.716,
        fontStyle: "normal",
        fontWeight: 400,
        letterSpacing: "-2.989px",
        position: "absolute",
        zIndex: 3,

        "&.left": {
          top: "10%",
          left: "10%",
        },
        "&.right": {
          //color: "#D7DCE8",
          color: "#687390",
          bottom: "10%",
          right: "10%",
          opacity: 0.5,
        },
      },
    },
    ".text": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignContent: "center",
      justifyContent: "center",
      alignItems: "center",
      marginTop: -40,

      p: {
        color: "rgba(27, 36, 58, 0.80)",
        fontFamily: "Suisse Intl",
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        textAlign: "center",
        maxWidth: 1048,
      },

      img: {
        marginBottom: 41,
        textAlign: "center",
      },
    },

    [desktop]: {
      marginTop: 200,
      ".header": {
        h2: {
          fontSize: 68,
          letterSpacing: "-3px",

          "&.left": {
            top: "20%",
            left: "0",
          },
          "&.right": {
            bottom: "10%",
            right: "0%",
          },
        },
      },
    },
    [laptop]: {
      ".wrapper": {
        paddingTop: 0,
        paddingLeft: 60,
        paddingRight: 60,
        paddingBottom: 40,
      },
      ".text": {
        p: {
          fontSize: 18,
          lineHeight: "30px",
        },
      },
      ".header": {
        img: {
          width: "100%",
        },
        h2: {
          fontSize: 46,
          letterSpacing: "-1.8px",

          "&.left": {
            top: "25%",
          },
          "&.right": {
            bottom: "20%",
          },
        },
      },
    },
    [tablet]: {
      marginTop: 100,
      ".wrapper": {
        margin: 20,
        paddingTop: 100,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 60,
      },
      ".text": {
        marginTop: 80,
        p: {
          fontSize: 16,
        },
      },
      ".header": {
        img: {
          width: "100%",
        },
        h2: {
          fontSize: 46,
          letterSpacing: "-1.8px",

          "&.left": {
            top: "0%",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
          },
          "&.right": {
            color: "#D7DCE8",
            opacity: 1,
            bottom: "-10%",
            marginLeft: "auto",
            marginRight: "auto",
            left: 0,
            right: 0,
            textAlign: "center",
          },
        },
      },
    },
    [mobile]: {
      marginTop: 80,
      ".wrapper": {
        paddingLeft: 20,
        paddingRight: 20,
      },
      ".text": {
        marginTop: 60,
        p: {
          fontSize: 14,
        },
        img: {
          marginBottom: 20,
        },
      },
      ".header": {
        h2: {
          fontSize: 36,
          letterSpacing: "-1.8px",


          "&.right": {
            bottom: "-20%",
          },
        },
      },
    }
  }),
};

export default About;
